import { Container, Tab, Tabs, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useState } from 'react'
import SubZoneDisplay from "./components/subZone/SubZoneDisplay"
import WorkZone from "./components/workZone/WorkZone"
import Maintenance from "./components/maintenance/Maintenance"
import Ergonomics from "./components/ergonomics/Ergonomics"

interface ZonesProps {
    highlighting: boolean;
    dateRange: [Date | undefined, Date | undefined];
    currentArea: string;
    currentSubArea: string | null;
  }

function Zones ( {
    highlighting,
    dateRange,
    currentArea,
    currentSubArea,
  }: ZonesProps) {

    const [selectedTab, setSelectedTab] = useState<string>("workZone");

    // Function to handle tab change
    const handleTabChange = (event: React.SyntheticEvent, newSubTab: string) => {
        setSelectedTab(newSubTab);
    };

    return (
      !currentSubArea ?(<Container sx={{marginTop:'2%'}}>
       <ToggleButtonGroup
      value={selectedTab}
      exclusive
      onChange={handleTabChange}
      aria-label="text alignment"
      sx={{ width: "100%", height: "50px" }}
    >
    
      <ToggleButton
        sx={{ width: "50%", textTransform: "none" }}
        value="workZone"
        aria-label="workZone"
      >
        <h4>Work Zone</h4>
      </ToggleButton>

      <ToggleButton
        sx={{ width: "50%", textTransform: "none" }}
        value="maintenance"
        aria-label="maintenance"
      >
        <h4>Maintenance</h4>
      </ToggleButton>
      <ToggleButton
        sx={{ width: "50%", textTransform: "none" }}
        value="ergonomics"
        aria-label="ergonomics"
      >
        <h4>Ergonomics</h4>
      </ToggleButton>
      </ToggleButtonGroup>

        {selectedTab === "workZone" && <WorkZone highlighting={highlighting}
          dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea} />}
        {selectedTab === "maintenance" && <Maintenance highlighting={highlighting}
          dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea} />}
        {selectedTab === "ergonomics" && <Ergonomics highlighting={highlighting}
          dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea} />}
        

       </Container>)

       :
       <SubZoneDisplay highlighting={highlighting}
       dateRange={dateRange}
       currentArea={currentArea}
       currentSubArea={currentSubArea}/>
       
    )
}

export default Zones