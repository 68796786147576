import { Box } from "@mui/material";
import TableOne from "./components/TableOne";
import TableTwo from "./components/TableTwo";
import TableThree from "./components/TableThree";
import ChartOne from "./components/ChartOne";

interface WorkZoneProps {
    highlighting: boolean;
    dateRange: [Date | undefined, Date | undefined];
    currentArea: string;
    currentSubArea: string | null;
}
function WorkZone({highlighting,dateRange,currentArea,currentSubArea}:WorkZoneProps) {
  return (
    <Box>
      <Box display="flex" flexDirection="row">
          <TableOne highlighting={highlighting}
          dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea}
          />
        <TableTwo highlighting={highlighting}
          dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea}
          />
          </Box>
        <TableThree highlighting={highlighting}
          dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea}/>
        {/* <ChartOne /> */}


    </Box>
  )
}

export default WorkZone