import TableOne from "./components/TableOne"

interface ErgonomicsProps {
    highlighting: boolean;
    dateRange: [Date | undefined, Date | undefined];
    currentArea: string;
    currentSubArea: string | null;
  }

function Ergonomics({highlighting,dateRange,currentArea,currentSubArea}:ErgonomicsProps) {
  return (
    <div>
        <TableOne
         highlighting={highlighting}
         dateRange={dateRange}
         currentArea={currentArea}
         currentSubArea={currentSubArea}
        />
    </div>
  )
}

export default Ergonomics