import React, { useState,useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Box,
  Typography,
  CircularProgress
} from "@mui/material";
import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL;

// // Define the data type
// interface DataRow {
//   zone: string;
//   percentageTimeSpent: number;
// }
// Define the data type
// interface DataRow {
//   employee: string;
//   percentTimeInMaint: number;
// }
interface DataRow {
  employeeName: string;
  percentTimeInMaint: number;
}


interface TableTwoProps {
  highlighting: boolean;
  dateRange: [Date | undefined, Date | undefined];
  currentArea: string;
  currentSubArea: string | null;
}

const getColorForColumn = (column: keyof DataRow, value: number): string => {
  switch (column) {
    case "percentTimeInMaint":
      if (value >= 0.5) return "#b0c93e"; // Green-yellow (0.5%+)
      if (value >= 0.4) return "#cbd934"; // Yellow (0.4-0.49%)
      if (value >= 0.3) return "#d99134"; // Orange (0.3-0.39%)
      if (value >= 0.2) return "#d96b34"; // Dark orange (0.2-0.29%)
      if (value >= 0.1) return "#d94434"; // Tomato (0.1-0.19%)
      return "#f2f2f2"; // Default light gray for values below 0.1%

    default:
      return "#f2f2f2"; // Default light gray
  }
};


type Order = "asc" | "desc";

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = <T,>(
  order: Order,
  orderBy: keyof T
): ((a: T, b: T) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = <T,>(
  array: T[],
  comparator: (a: T, b: T) => number
): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

function TableTwo({
  highlighting,
  dateRange,
  currentArea,
  currentSubArea,
}: TableTwoProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof DataRow>("percentTimeInMaint");
  const [data, setData] = useState<DataRow[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Loading state

  const handleRequestSort = (property: keyof DataRow) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (!dateRange[0] || !dateRange[1]) {
      console.log("Please select a date range");
      return;
    }

    const fetchData = async () => {
      setLoading(true); // Set loading to true when fetching begins
      try {
        // const response = await axios.get(`${apiUrl}/api/work-zone-data`, 
        const response = await axios.get(`${apiUrl}/api/employees-maintenance-time`, 
          {
          params: {
            startDate: dateRange[0]?.toISOString(), 
            endDate: dateRange[1]?.toISOString(), 
            currentArea: currentArea ? currentArea : currentSubArea 
          },
        });
        // console.log("Latest Query Received:", response.data);
        setData(response.data); // Take bottom row zones
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); 
      }
    };
    fetchData();
  }, [dateRange, currentArea,currentSubArea]);

  const sortedData = data ? stableSort(data, getComparator(order, orderBy)) : [];

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ maxHeight: 325, overflow: "auto" }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            paddingTop: "1%",
            textAlign: "center",
            fontSize: "15px",
          }}
        >
          Employees - {currentArea}{" "}
          {currentArea && currentArea !== "fullSite" && currentSubArea}
        </Typography>
        {loading ? (
          // Display the loading spinner when loading state is true
          <Box sx={{ display: "flex", justifyContent: "center", padding: "20px" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {[
                  { id: "employeeName", label: "Employee Name" },
                  { id: "percentTimeInMaint", label: "% Time in Maint Zone" },
                ].map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align="right"
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{
                      padding: "2px 8px",
                      overflow: "hidden",
                      fontWeight: "bold",
                      textOverflow: "ellipsis",
                    }} // Reduced padding and nowrap
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort(headCell.id as keyof DataRow)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row, index) => (
                <TableRow key={index} sx={{ height: 24 }}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      padding: "2px 8px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight: "bold",
                    }} // Reduced padding and nowrap
                  >
                    {row.employeeName}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      backgroundColor: highlighting
                        ? getColorForColumn("percentTimeInMaint", row.percentTimeInMaint)
                        : undefined,
                      padding: "2px 8px", // Reduced padding
                      fontWeight: "bold",
                    }}
                  >
                    {row.percentTimeInMaint}%
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
}

export default TableTwo;