
type LatLngTuple = [number, number];

interface Square {
  id: string;
  bounds: [LatLngTuple, LatLngTuple]; // Array of two lat/lng pairs
  color: string
  textBounds: [LatLngTuple, LatLngTuple]
  fontSize: string
}

// Bounds for squares
const squareBoundsOne: [LatLngTuple, LatLngTuple] = [ //Rework
  [51.49508, -0.0739], 
  [51.496, -0.0723],
];

const squareBoundsTwo: [LatLngTuple, LatLngTuple] = [ //Oven
  [51.4939, -0.0739], 
  [51.49495, -0.0723],
];

const squareBoundsThree: [LatLngTuple, LatLngTuple] = [ // Powder Coat
  [51.49365, -0.0676], 
  [51.4942, -0.0654],
];

const squareBoundsFour: [LatLngTuple, LatLngTuple] = [ //Grinder 2
  [51.49455, -0.06565], 
  [51.49523, -0.0646],
];

const squareBoundsFive: [LatLngTuple, LatLngTuple] = [ //Grinder 1
  [51.49457, -0.0682], 
  [51.49535, -0.0668],
];

const squareBoundsSix: [LatLngTuple, LatLngTuple] = [ // Inspection
  [51.49536, -0.0667], 
  [51.4960, -0.0646],
];
const squareBoundsSeven: [LatLngTuple, LatLngTuple] = [ // Printer
  [51.49576, -0.0682], 
  [51.4964, -0.06688],
];
const squareBoundsEight: [LatLngTuple, LatLngTuple] = [ //Grinder Three
  [51.4956, -0.0703], 
  [51.4962, -0.0684],
];
const squareBoundsNine: [LatLngTuple,LatLngTuple]= [ // This one is for a text box essentially
  [0,0.1],
  [0,0.1],
]
const squareBoundsTen: [LatLngTuple, LatLngTuple] = [ //Loading
  [51.49476, -0.066759], 
  [51.49535, -0.0657],
];

// Bounds for text positions
const textBoundsOne: [LatLngTuple, LatLngTuple] = [
  [51.49508, -0.078], 
  [51.496, -0.072],
]
const textBoundsTwo: [LatLngTuple, LatLngTuple] = [
  [51.4941, -0.0745], 
  [51.49495, -0.0755],
]
const textBoundsThree: [LatLngTuple, LatLngTuple] = [
  [51.49365, -0.0676], 
  [51.4944, -0.062],
]
const textBoundsFour: [LatLngTuple, LatLngTuple] = [ // Grinder Two
  [51.49494, -0.068], 
  [51.49523, -0.058],
]
const textBoundsFive: [LatLngTuple, LatLngTuple] = [
  [51.49477, -0.06789], 
  [51.49554, -0.07],
]
const textBoundsSix: [LatLngTuple, LatLngTuple] = [ // Inspection
  [51.4954, -0.071], 
  [51.4971, -0.06],
]
const textBoundsSeven: [LatLngTuple, LatLngTuple] = [ // Printer
  [51.49565, -0.069], 
  [51.4978, -0.0662],
]
const textBoundsEight: [LatLngTuple, LatLngTuple] = [ // Grinder Three
  [51.496, -0.07], 
  [51.4975, -0.069],
]
const textBoundsNine: [LatLngTuple, LatLngTuple] = [
  [51.495, -0.053], 
  [51.497, -0.072],
]
const textBoundsTen: [LatLngTuple, LatLngTuple] = [
  [51.49, -0.053], 
  [51.4975, -0.072],
]
const textBoundsEleven: [LatLngTuple, LatLngTuple] = [ // Loading
  [51.49457, -0.0662], 
  [51.4943, -0.0668],
]

// Export the squares data as an array of Square objects
const TL04GL01Squares: Square[] = [
  { id: "Rework", bounds: squareBoundsOne, color:'red',textBounds: textBoundsOne, fontSize: '12px'  },
  { id: "Oven", bounds: squareBoundsTwo, color:'red',textBounds: textBoundsTwo, fontSize: '12px' },
  { id: "Powder Coat", bounds: squareBoundsThree, color:'red', textBounds: textBoundsThree, fontSize: '12px' },
  { id: "Grinder Two", bounds: squareBoundsFour, color:'red', textBounds: textBoundsFour, fontSize: '12px' },
  { id: "Grinder One", bounds: squareBoundsFive, color:'red', textBounds: textBoundsFive, fontSize: '12px' },
  { id: "Inspection", bounds: squareBoundsSix, color:'green',textBounds: textBoundsSix, fontSize: '12px' },
  { id: "Printer", bounds: squareBoundsSeven, color:'red',textBounds: textBoundsSeven, fontSize: '12px' },
  { id: "Grinder Three", bounds: squareBoundsEight, color:'red',textBounds: textBoundsEight, fontSize: '12px' },
  { id: "TL04", bounds: squareBoundsNine, color:'white',textBounds: textBoundsNine, fontSize: '17px' },
  { id: "GL01", bounds: squareBoundsNine, color:'white',textBounds: textBoundsTen, fontSize: '17px' },
  { id: "Loading", bounds: squareBoundsTen, color:'green',textBounds: textBoundsEleven, fontSize: '12px' },
];

export default TL04GL01Squares;
