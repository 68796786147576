import React, { useState, useEffect } from "react";
import axios from 'axios'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Box,
  Typography,
  CircularProgress
} from "@mui/material";

interface DataRow {
  zone: string;
  totalTimeInHoursMinutes: number
  percentTimeInZone: number;
  percentTimeInMaint: number;
  longestTimeSpent: string;
  // timeVisited: number;
  // timeVisitedMaintUP: number;
  // timeVisitedMaintDown: number;
}

interface TableThreeProps {
  highlighting: boolean;
  dateRange: [Date | undefined, Date | undefined];
  currentArea: string;
  selectedEmployee: string;
}

type Order = "asc" | "desc";

const apiUrl = process.env.REACT_APP_API_URL;

// Function to return color based on percentage
const getColorForColumn = (column: keyof DataRow, value: number): string => {
  switch (column) {
    case "percentTimeInZone":
    case "percentTimeInMaint":
      if (value >= 20) return "#0c5720";   // Dark green for high values
      if (value >= 15) return "#47a02f";   // Strong green
      if (value >= 10) return "#75d62b";   // Medium green
      if (value >= 5) return "#99c93e";    // Light green
      if (value >= 1) return "#d94434";    // Tomato red for low values
      return "#f2f2f2";                    // Default light gray
    default:
      return "#f2f2f2";
  }
};

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = <T,>(
  order: Order,
  orderBy: keyof T
): ((a: T, b: T) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = <T,>(
  array: T[],
  comparator: (a: T, b: T) => number
): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

function TableThree({highlighting, dateRange, currentArea, selectedEmployee}: TableThreeProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof DataRow>("zone");
  const [data, setData] = useState<DataRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleRequestSort = (property: keyof DataRow) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
useEffect(() => {
    if (!dateRange[0] || !dateRange[1] || !currentArea || !selectedEmployee) {
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/api/cp-employee-zone-visit-summary`, {
            params: {
              startDate: dateRange[0]?.toISOString(),
              endDate: dateRange[1]?.toISOString(),
              employeeName: selectedEmployee  // Use deviceId instead of employeeId
            }
          });
          // const formattedData = response.data.map((item: any) => ({
          //   employeeName: item.employeeName || "N/A",
          //   totalTimeInCPArea: item.totalTimeInCPArea || "0 min",
          //   percentageInDownCurePressMaint: item.percentageInDownCurePressMaint || 0,
          //   longestTime: item.longestTime || "0 min",
          // }));
    
          // // Update the state with formatted data
          setData(response.data);
      } catch (error) {
        console.error("Error fetching zone data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dateRange, currentArea, selectedEmployee]);  

  const sortedData = stableSort(data, getComparator(order, orderBy));

  return (
    <>
      <TableContainer component={Paper} style={{ maxHeight: 500, overflow: "auto" }}>
        {/* <Typography
          sx={{
            fontWeight: "bold",
            paddingTop: "1%",
            fontSize: "15px",
            marginLeft: "2%"
          }}
        >
          Zone Visit Summary
        </Typography> */}
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", padding: "20px" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Table stickyHeader>
            <TableHead sx={{ zIndex: 0 }}>
              <TableRow>
                {[
                  { id: "zone", label: "Zone" },
                  { id: "totalTimeInHoursMinutes", label: "Total Time" },
                  { id: "percentTimeInZone", label: "% Time in Zone" },
                  // { id: "percentTimeInMaint", label: "% Time in Maint" },
                  { id: "longestTimeSpent", label: "Longest Time Spent" },
                  // { id: "timeVisited", label: "Time Visited" },
                ].map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align="right"
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{
                      padding: "2px 8px",
                      overflow: "hidden",
                      fontWeight: "bold",
                      textOverflow: "ellipsis",
                      zIndex: 0
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort(headCell.id as keyof DataRow)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row, index) => (
                <TableRow key={index} sx={{ height: 24 }}>
                  <TableCell align="right" sx={{ padding: "2px 8px", fontWeight: "bold" }}>
                    {row.zone}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: "2px 8px", fontWeight: "bold" }}>
                    {row.totalTimeInHoursMinutes}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      backgroundColor: highlighting
                        ? getColorForColumn("percentTimeInZone", row.percentTimeInZone)
                        : undefined,
                      padding: "2px 8px",
                      fontWeight: "bold"
                    }}
                  >
                    {row.percentTimeInZone !== undefined ? `${row.percentTimeInZone}%` : "0%"}
                  </TableCell>
                  {/* <TableCell
                    align="right"
                    style={{
                      backgroundColor: highlighting
                        ? getColorForColumn("percentTimeInMaint", row.percentTimeInMaint)
                        : undefined,
                      padding: "2px 8px",
                      fontWeight: "bold"
                    }}
                  >
                     {row.percentTimeInMaint !== undefined ? `${row.percentTimeInMaint}%` : "0%"}
                  </TableCell> */}
                  <TableCell align="right" sx={{ padding: "2px 8px", fontWeight: "bold" }}>
                    {row.longestTimeSpent}
                  </TableCell>
                  {/* <TableCell align="right" sx={{ padding: "2px 8px", fontWeight: "bold" }}>
                    {row.timeVisited}
                  </TableCell> */}
                  {/* <TableCell align="right" sx={{ padding: "2px 8px", fontWeight: "bold" }}>
                    {row.timeVisitedMaintUP}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: "2px 8px", fontWeight: "bold" }}>
                    {row.timeVisitedMaintDown}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
}

export default TableThree;
