
type LatLngTuple = [number, number];

interface Square {
  id: string;
  bounds: [LatLngTuple, LatLngTuple]; // Array of two lat/lng pairs
  color: string
  textBounds: [LatLngTuple, LatLngTuple]
  fontSize: string
}

// Bounds for squares
const squareBoundsOne: [LatLngTuple, LatLngTuple] = [ 
  [51.49578, -0.077], 
  [51.4973, -0.0752],
];

const squareBoundsTwo: [LatLngTuple, LatLngTuple] = [ 
  [51.49578, -0.0752], 
  [51.4965, -0.0738],
];

const squareBoundsThree: [LatLngTuple, LatLngTuple] = [ 
  [51.494, -0.077], 
  [51.4953, -0.0746],
];

const squareBoundsFour: [LatLngTuple, LatLngTuple] = [ 
  [51.4924, -0.077], 
  [51.4937, -0.0746],
];

const squareBoundsFive: [LatLngTuple, LatLngTuple] = [ 
  [51.49475, -0.0728], 
  [51.4953, -0.070],
];
const squareBoundsSix: [LatLngTuple, LatLngTuple] = [ 
  [51.4924, -0.0736], 
  [51.4931, -0.0707],
];
const squareBoundsSeven: [LatLngTuple, LatLngTuple] = [ 
  [51.4931, -0.0736], 
  [51.49382, -0.0707],
];
const squareBoundsEight: [LatLngTuple, LatLngTuple] = [ 
  [51.492, -0.0706], 
  [51.4927, -0.0676],
];
const squareBoundsNine: [LatLngTuple,LatLngTuple]= [ // This one is for a text box essentially
  [0,0.1],
  [0,0.1],
]
const squareBoundsTen: [LatLngTuple, LatLngTuple] = [ 
  [51.49366, -0.0651], 
  [51.4951, -0.0636],
];
const squareBoundsEleven: [LatLngTuple, LatLngTuple] = [ 
  [51.4965, -0.07515], 
  [51.4973, -0.0716],
];
const squareBoundsTwelve: [LatLngTuple, LatLngTuple] = [ 
  [51.4965, -0.0715], 
  [51.4973, -0.0688],
];

// Bounds for text positions
const textBoundsOne: [LatLngTuple, LatLngTuple] = [
  [51.49578, -0.082], 
  [51.4973, -0.0752],
]
const textBoundsTwo: [LatLngTuple, LatLngTuple] = [
  [51.49578, -0.073], 
  [51.4965, -0.0738],
]
const textBoundsThree: [LatLngTuple, LatLngTuple] = [
  [51.494, -0.0825], 
  [51.4953, -0.0746],
]
const textBoundsFour: [LatLngTuple, LatLngTuple] = [ 
  [51.4924, -0.0826], 
  [51.4937, -0.0746],
]
const textBoundsFive: [LatLngTuple, LatLngTuple] = [
  [51.49475, -0.0789], 
  [51.4953, -0.070],
]
const textBoundsSix: [LatLngTuple, LatLngTuple] = [ 
  [51.4913, -0.074], 
  [51.4931, -0.0707],
]
const textBoundsSeven: [LatLngTuple, LatLngTuple] = [ 
  [51.4931, -0.0742], 
  [51.49382, -0.0707],
]
const textBoundsEight: [LatLngTuple, LatLngTuple] = [ 
  [51.492, -0.0706], 
  [51.4915, -0.068],
]
const textBoundsNine: [LatLngTuple, LatLngTuple] = [
  [51.495, -0.053], 
  [51.499, -0.072],
]
const textBoundsTen: [LatLngTuple, LatLngTuple] = [
  [51.491, -0.0612], 
  [51.4951, -0.0636],
]
const textBoundsEleven: [LatLngTuple, LatLngTuple] = [ 
  [51.4915, -0.055], 
  [51.4973, -0.0716],
]
const textBoundsTwelve: [LatLngTuple, LatLngTuple] = [ 
  [51.4965, -0.076], 
  [51.499, -0.0716],
]
const textBoundsThirteen: [LatLngTuple, LatLngTuple] = [ 
  [51.4965, -0.0695], 
  [51.499, -0.0716],
]

// Export the squares data as an array of Square objects
const TL01CL09Squares: Square[] = [
  { id: "Inspection One", bounds: squareBoundsOne, color:'green',textBounds: textBoundsOne, fontSize: '12px'  },
  { id: "Printer", bounds: squareBoundsTwo, color:'red',textBounds: textBoundsTwo, fontSize: '12px' },
  { id: "Loading", bounds: squareBoundsThree, color:'green', textBounds: textBoundsThree, fontSize: '12px' },
  { id: "Inspection Two", bounds: squareBoundsFour, color:'green', textBounds: textBoundsFour, fontSize: '12px' },
  { id: "Powder/Vacuum", bounds: squareBoundsFive, color:'red', textBounds: textBoundsFive, fontSize: '12px' },
  { id: "Inspection Three", bounds: squareBoundsSix, color:'green',textBounds: textBoundsSix, fontSize: '12px' },
  { id: "Grinder One", bounds: squareBoundsSeven, color:'red',textBounds: textBoundsSeven, fontSize: '12px' },
  { id: "Grinder Two", bounds: squareBoundsEight, color:'red',textBounds: textBoundsEight, fontSize: '12px' },
  { id: "CL09", bounds: squareBoundsNine, color:'white',textBounds: textBoundsNine, fontSize: '17px' },
  { id: "TL01", bounds: squareBoundsNine, color:'white',textBounds: textBoundsTen, fontSize: '17px' },
  { id: "Oven", bounds: squareBoundsTen, color:'red',textBounds: textBoundsEleven, fontSize: '12px' },
  { id: "Loading", bounds: squareBoundsEleven, color:'green',textBounds: textBoundsTwelve, fontSize: '12px' },
  { id: "Scorching", bounds: squareBoundsTwelve, color:'red',textBounds: textBoundsThirteen, fontSize: '12px' },
];

export default TL01CL09Squares;
