import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Box,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

interface DataRow {
  employeeName: string;
  totalTimeInCPArea: string;
  percentTimeInCPWorkZone: string;
  percentageInDownCurePressMaint: number;
  percentageInUpCurePressMaint: number;
  longestTime: string;
}

interface TableOneProps {
  highlighting: boolean;
  dateRange: [Date | undefined, Date | undefined];
  currentArea: string;
  selectedEmployee: string;
  currentSubArea: string | null;
}

// Function to return color based on percentageTimeSpent
const getColorForColumn = (column: keyof DataRow, value: number): string => {
  switch (column) {
    case "percentageInDownCurePressMaint":
      if (value >= 20) return "#0c5720";   // Dark green for the highest values (20%+)
      if (value >= 15) return "#47a02f";   // Strong green (15-19%)
      if (value >= 10) return "#75d62b";   // Medium green (10-14%)
      if (value >= 8)  return "#99c93e";   // Light green (8-9%)
      if (value >= 6)  return "#b0c93e";   // Green-yellow (6-7%)
      if (value >= 4)  return "#cbd934";   // Yellow (4-5%)
      if (value >= 2)  return "#d99134";   // Orange (2-3%)
      if (value >= 1)  return "#d96b34";   // Dark orange (1-1.9%)
      if (value >= 0.5) return "#d94434";  // Tomato (0.5-0.9%)
      return "#f2f2f2"; // Default light gray
    default:
      return "#f2f2f2"; // Default light gray
  }
};

type Order = "asc" | "desc";

const convertTimeToMinutes = (timeString: string): number => {
  // Handle case where timeString is empty or undefined
  if (!timeString) return 0;

  // Match hours and minutes in the format "X hour(s)" and "Y min(s)"
  const timeParts = timeString.match(/(\d+)\s*(hour|min)/g);
  let totalMinutes = 0;

  if (timeParts) {
    timeParts.forEach((part) => {
      const [value, unit] = part.split(" ");
      if (unit.includes("hour")) {
        totalMinutes += parseInt(value, 10) * 60; // Convert hours to minutes
      } else if (unit.includes("min")) {
        totalMinutes += parseInt(value, 10); // Add minutes
      }
    });
  }

  return totalMinutes;
};

// Sorting helpers
const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
  if (orderBy === "totalTimeInCPArea") {
    const aTime = convertTimeToMinutes(a[orderBy] as unknown as string);
    const bTime = convertTimeToMinutes(b[orderBy] as unknown as string);
    return bTime - aTime; // Sort by total minutes
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = <T,>(
  order: Order,
  orderBy: keyof T
): ((a: T, b: T) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = <T,>(
  array: T[],
  comparator: (a: T, b: T) => number
): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

function TableOne({
  highlighting,
  dateRange,
  currentArea,
  selectedEmployee,
  currentSubArea
}: TableOneProps) {

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof DataRow>("employeeName");
  const [data, setData] = useState<DataRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleRequestSort = (property: keyof DataRow) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (!dateRange[0] || !dateRange[1] || !currentArea || !selectedEmployee) {
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        // const response = await axios.get(`${apiUrl}/api/cp-employee-zones-summary`, 
        const response = await axios.get(`${apiUrl}/api/employee-workzone-data`, 
          {
            params: {
              startDate: dateRange[0]?.toISOString(),
              endDate: dateRange[1]?.toISOString(),
              currentArea: currentArea
            }
          });
          // const formattedData = response.data.map((item: any) => ({
          //   employeeName: item.employeeName || "N/A",
          //   totalTimeInCPArea: item.totalTimeInCPArea || "0 min",
          //   percentTimeInCPWorkZone: item.percentTimeInCPWorkZone || "0%",
          //   percentageInDownCurePressMaint: item.percentageInDownCurePressMaint || 0,
          //   percentageInUpCurePressMaint: item.percentageInUpCurePressMaint || 0,
          // }));
    
          // Update the state with formatted data
          console.log('This is the latest data', response.data)
          // setData(response.data);
      } catch (error) {
        console.error("Error fetching zone data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dateRange, currentArea,]);  

  const sortedData = stableSort(data, getComparator(order, orderBy));

  return (
    <>
      <TableContainer component={Paper} style={{ maxHeight: 500, overflow: "auto" }}>
        {/* <Typography
          sx={{
            fontWeight: "bold",
            paddingTop: "1%",
            fontSize: "15px",
            marginLeft: "2%",
          }}
        >
          CP Work Zone Data
        </Typography> */}
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", padding: "20px" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Table stickyHeader>
            <TableHead sx={{zIndex:0}}>
              <TableRow>
                {[
                  { id: "employeeName", label: "Employee" },
                  { id: "totalTimeInArea", label: (currentSubArea ? `Total Time in ${currentSubArea} Area` :`Total Time in ${currentArea}`) },
                  { id: "percentTimeInWorkZone", label: `% Time in ${currentArea}  Work Zones` },
                  // { id: "percentTimeInMaintZone", label: (currentSubArea ?`% Time in ${currentSubArea} Maint` : `% of Time in ${currentArea} Maintenance Zones`) },
                  // { id: "percentTimeInMaintZone", label: `% of Time in ${currentArea} Other` },
                  // { id: "percentageInUpCurePressMaint", label: "% of Time in Up CP Maint Zone" },
                  // { id: "%timeUpCPMaintZone", label: "% of Time in Up CP Maint Zone" },
                  // { id: "averageTimePerCP", label: "Average Time Per CP" },
                  // { id: "zone", label: "Zone" },
                  // { id: "timeSpent", label: "Time in Zone" },
                  // { id: "percentageTimeSpent", label: "% Time" },
                //   { id: "longestTime", label: "Longest" }
                ].map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align="right"
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{
                      padding: "2px 8px",
                      overflow: "hidden",
                      fontWeight: "bold",
                      textOverflow: "ellipsis",
                      zIndex:0
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleRequestSort(headCell.id as keyof DataRow)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row, index) => (
                <TableRow key={index} sx={{ height: 24 }}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      padding: "2px 8px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight: "bold",
                    }}
                  >
                    {row.employeeName}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: "2px 8px", fontWeight: "bold" }}> 
                    {row.totalTimeInCPArea} 
                  </TableCell>
                  <TableCell align="right" sx={{ padding: "2px 8px", fontWeight: "bold" }}>
                    {row.percentageInDownCurePressMaint}
                  </TableCell>
                  {/* <TableCell
                    align="right"
                    style={{
                      backgroundColor: highlighting
                        ? getColorForColumn("percentageInDownCurePressMaint", row.percentageInDownCurePressMaint)
                        : undefined,
                      padding: "2px 8px",
                      fontWeight: "bold",
                    }}
                  >
                    {row.percentageInDownCurePressMaint ? `${row.percentageInDownCurePressMaint}%` : ""}
                  </TableCell> */}
                  {/* <TableCell align="right" sx={{ padding: "2px 8px", fontWeight: "bold" }}>
                    {row.longestTime}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
}

export default TableOne;



